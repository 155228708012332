/* Fonts */
@font-face {
  font-family: Nunito Sans;
  src: url(../assets/fonts/NunitoSans/NunitoSans-Regular.ttf);
}

/* Colors */
:root {
  --ah-grey: #e2e8f0;
  --ah-light: #f5f7fa;

  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
